import { useState, useEffect, Fragment } from "react";
import { cn } from "@/lib/utils";
import { CircleUser, Fence, Plus, Waypoints } from "lucide-react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  DialogDarkBody,
  DialogDarkContent,
  DialogDarkHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  Dialog,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ConnectionTag } from "./connection-tag";
import { Input } from "@/components/ui/input";
import { fetchConnectionOptions } from "../api";
import { ConnectionList } from "./connection-list";
import { Separator } from "@/components/ui/separator";

const defaultOrder = [
  "Labels",
  "People",
  "Property",
  "Financial Accounts",
  "Insurance Providers",
  "Digital Accounts",
  "Documents",
];

export function AddConnectionDialog({
  open,
  onOpenChange,
  item: existingItem,
  connections,
  saveConnections,
  order = defaultOrder,
}) {
  const [activeTab, setActiveTab] = useState("All");
  const [loading, setLoading] = useState(true);
  const [pendingConnections, setPendingConnections] = useState(connections);
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [reloadCount, setReloadCount] = useState(0);

  useEffect(() => {
    fetchConnectionOptions().then((res) => {
      const newData = transformData(res.data);
      setConnectionOptions(newData);
      setLoading(false);
    });
  }, [reloadCount]);

  function addPendingConnection({ id, type, name }) {
    setPendingConnections([
      ...pendingConnections,
      { id, type, name, confidence_score: 100 },
    ]);
  }

  function removeConnection({ id, type }) {
    setPendingConnections(
      pendingConnections.filter((c) => !(c.id === id && c.type === type))
    );
  }

  function reload() {
    setReloadCount(reloadCount + 1);
  }

  const tabs = [
    "All",
    "Documents",
    "People",
    "Financial Accounts",
    "Insurance Providers",
    "Digital Accounts",
    "Property",
    "Labels",
  ];

  const reorderedTabs = [
    "All",
    ...tabs
      .filter((tab) => tab !== "All")
      .sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b);
      }),
  ];
  const items = [
    { name: "labels", tab: "Labels" },
    { name: "people", tab: "People" },
    { name: "financial_accounts", tab: "Financial Accounts" },
    { name: "properties", tab: "Property" },
    { name: "insurance_providers", tab: "Insurance Providers" },
    { name: "digital_accounts", tab: "Digital Accounts" },
    { name: "documents", tab: "Documents" },
  ];

  const sortedItems = items.sort((a, b) => {
    return order.indexOf(a.tab) - order.indexOf(b.tab);
  });

  // .sort((a, b) => {
  //   if (a.tab === activeTab) return -1;
  //   if (b.tab === activeTab) return 1;
  //   return 0;
  // });

  useEffect(() => {
    setPendingConnections(connections);
  }, [connections]);
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogDarkContent className="sm:max-w-[600px] overflow-y-auto h-full sm:h-full">
        <DialogDarkHeader>
          <DialogTitle>Add threads</DialogTitle>
          <DialogDescription className="sr-only">
            Add threads to your item.
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <div className="flex gap-1 items-center">
            <Waypoints className="w-4 h-4 text-muted-foreground" />
            <span className="font-sans font-medium text-sm">
              Selected Threads
            </span>
          </div>
          <div className="flex mt-4 gap-2 flex-wrap">
            {pendingConnections.map((connection) => (
              <ConnectionTag
                {...connection}
                key={connection.id}
                removeConnection={removeConnection}
              />
            ))}
          </div>

          <div className="flex gap-1 mt-8 items-center">
            <span className="font-sans font-medium text-sm">
              Add Additional Threads
            </span>
          </div>
          <div className="border rounded-lg flex items-center w-full mt-2">
            <MagnifyingGlassIcon className="w-4 h-4 opacity-75 ml-2" />
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border-none shadow-none focus-visible:ring-none focus-visible:ring-0"
            />
          </div>
          <div className="mt-8 flex gap-2 flex-wrap justify-center xs:justify-start">
            {reorderedTabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={cn(
                  "px-3 py-2 text-sm font-medium rounded-full transition-colors",
                  activeTab === tab
                    ? "bg-brandBlue text-white"
                    : "text-gray-500 hover:text-gray-700"
                )}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="max-h-[250px] overflow-y-auto overflow-x-hidden mt-8">
            {sortedItems.map((item, index) => {
              if (
                activeTab !== "All" &&
                !searchTerm &&
                item.tab !== activeTab
              ) {
                return <Fragment key={index}></Fragment>;
              }
              return (
                <Fragment key={index}>
                  <ConnectionList
                    existingItem={existingItem}
                    label={item.name}
                    loading={loading}
                    connectionOptions={connectionOptions}
                    addPendingConnection={addPendingConnection}
                    pendingConnections={pendingConnections}
                    searchTerm={searchTerm}
                    removeConnection={removeConnection}
                    reload={reload}
                  />
                  {index === 0 && searchTerm && activeTab !== "All" && (
                    <>
                      <Separator className="my-4" />
                      <div className="font-sans font-medium text-sm mb-4">
                        Matches from other categories
                      </div>
                    </>
                  )}
                </Fragment>
              );
            })}
          </div>
          <DialogFooter className="mt-4">
            <Button onClick={() => saveConnections(pendingConnections)}>
              Save
            </Button>
          </DialogFooter>
        </DialogDarkBody>
      </DialogDarkContent>
    </Dialog>
  );
}

function transformData(data) {
  // Extract digital accounts with account_type "financial"
  const financialAccounts = data.digital_accounts.filter(
    (account) => account.account_type === "financial"
  );

  // Modify the digital_accounts to exclude those with account_type "financial"
  const nonFinancialAccounts = data.digital_accounts.filter(
    (account) => account.account_type !== "financial"
  );

  // Add the "financial_accounts" key and update the "digital_accounts" key
  return {
    ...data,
    financial_accounts: financialAccounts,
    digital_accounts: nonFinancialAccounts,
  };
}
