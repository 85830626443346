import {
  CircleUser,
  Fence,
  FileText,
  CreditCard,
  Tag,
  Check,
  Umbrella,
} from "lucide-react";
import { cn } from "@/lib/utils";

export function ConnectionSelection({
  id,
  type,
  name,
  onClick,
  selected,
  removeConnection,
  addPendingConnection,
}) {
  return (
    <div
      onClick={() => {
        if (selected) {
          removeConnection({ id, type });
        } else {
          addPendingConnection({ id, type, name });
        }
      }}
      className={cn(
        "hover:bg-black/10 cursor-pointer relative flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled='true']:pointer-events-none data-[disabled='true']:opacity-50",
        selected ? "bg-gray-200" : "bg-none"
      )}
    >
      {selected && (
        <div className="mr-2 flex h-4 w-4 items-center justify-center">
          <Check className="h-3 w-3 text-green-800" />
        </div>
      )}
      {!selected && (
        <div className="mr-2 flex h-4 w-4 items-center justify-center">
          {type === "Person" && <CircleUser className="h-3 w-3" />}
          {type === "Property" && <Fence className="h-3 w-3" />}
          {type === "Document" && <FileText className="h-3 w-3" />}
          {type === "DigitalAccount" && <CreditCard className="h-3 w-3" />}
          {type === "Label" && <Tag className="h-3 w-3" />}
          {type === "InsuranceProvider" && <Umbrella className="h-3 w-3" />}
        </div>
      )}
      {name}
    </div>
  );
}
