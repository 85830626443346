import axios from "@/axios";

export function getStoryOfTheWeek() {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/prompts/story_of_the_week`
  );
}

export function getPeople() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/people`);
}

export function getStoriesWritten() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/written`);
}

export function getDocumentsStats() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/stats`);
}
