import { useState, useEffect } from "react";
import NumberTicker from "@/components/ui/number-ticker";
import { ActivityGrid } from "./activity-grid";
import { DocumentActivityChart } from "./document-activity-chart";
import AvatarCircles from "@/components/ui/avatar-circles";

import { getPeople, getStoriesWritten, getDocumentsStats } from "./api";
import { Skeleton } from "../ui/skeleton";
import { set } from "date-fns";
import { useUser } from "../providers/user-provider";

export function StatsCard() {
  return (
    <div className="border rounded-md shadow w-full mt-4 p-4 flex flex-row gap-4 justify-between transition-all duration-300 ease-in-out">
      <DocumentsStat />
      <PeopleStat />
      <StoriesStat />
    </div>
  );
}

function DocumentsStat() {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [months, setMonths] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    getDocumentsStats().then((response) => {
      setCount(response.data.document_count);
      setMonths(response.data.by_month);
      setLoading(false);
    });
  }, [user.active_flock_id]);

  return (
    <div className="items-center flex flex-col">
      <div className="text-5xl font-bold tracking-tighter">
        {loading ? (
          <div className="flex gap-1">
            <Skeleton className="w-6 h-12" />
            <Skeleton className="w-6 h-12" />
          </div>
        ) : count ? (
          <NumberTicker value={count} />
        ) : (
          <span>0</span>
        )}
      </div>
      <p className="text-center text-[0.70rem] uppercase text-muted-foreground">
        Documents Uploaded
      </p>
      <DocumentActivityChart months={months} />
    </div>
  );
}

function StoriesStat() {
  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const [months, setMonths] = useState([]);
  const { user } = useUser();
  useEffect(() => {
    getStoriesWritten().then((response) => {
      setStories(response.data.count);
      setMonths(response.data.months);
      setLoading(false);
    });
  }, [user.active_flock_id]);

  return (
    <div className="items-center flex flex-col">
      <div className="text-5xl font-bold tracking-tighter">
        {loading ? (
          <div className="flex gap-1">
            <Skeleton className="w-6 h-12" />
            <Skeleton className="w-6 h-12" />
          </div>
        ) : stories ? (
          <NumberTicker value={stories} />
        ) : (
          <span>0</span>
        )}
      </div>
      <p className="text-center text-[0.70rem] uppercase text-muted-foreground">
        Stories Written
      </p>
      <ActivityGrid months={months} />
    </div>
  );
}

function PeopleStat() {
  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);
  const { user } = useUser();
  useEffect(() => {
    getPeople().then((response) => {
      setPeople(response.data);
      setLoading(false);
    });
  }, [user.active_flock_id]);

  return (
    <div className="items-center flex flex-col items-center">
      <p className="text-5xl font-bold tracking-tighter">
        {loading ? (
          <div className="flex gap-1">
            <Skeleton className="w-6 h-12" />
            <Skeleton className="w-6 h-12" />
          </div>
        ) : (
          <NumberTicker value={people.length} />
        )}
      </p>
      {loading ? (
        <Skeleton className="mt-4 w-[120px] h-[30px]" />
      ) : (
        <>
          <p className="text-center text-[0.70rem] uppercase text-muted-foreground">
            Connected People
          </p>
          <AvatarCircles
            className="mt-2"
            avatarUrls={people.map((person) => ({
              imageUrl: person.image || "/images/placeholder.webp",
            }))}
          />
        </>
      )}
    </div>
  );
}
