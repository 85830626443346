import { useState, useEffect } from "react";
import {
  DialogDarkContent,
  DialogDarkBody,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogDarkTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

import { Skeleton } from "@/components/ui/skeleton";
import { Waypoints, PlusIcon, Info } from "lucide-react";
import { AddConnectionDialog } from "./add-connection-dialog";
import { ConnectionTag } from "./connection-tag";
import {
  createInsuranceProviderConnections,
  fetchProviderWithRecommendations,
} from "../api";

export function ConnectInsuranceProvider({ provider, onClose, reload }) {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savingProvider, setSavingProvider] = useState(false);
  const [addConnectionModalOpen, setAddConnectionModalOpen] = useState(false);

  useEffect(() => {
    fetchProviderWithRecommendations(provider.id)
      .then((res) => {
        setConnections(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function removeConnection({ id, type }) {
    setConnections(
      connections.filter((c) => !(c.id === id && c.type === type))
    );
  }

  function saveConnections(connections) {
    setSavingProvider(true);
    createInsuranceProviderConnections({
      providerId: provider.id,
      connections: connections.map(({ id, type }) => ({ id, type })),
    }).then((res) => {
      setSavingProvider(false);
      reload();
      onClose();
    });
  }

  if (loading) {
    return (
      <DialogDarkContent className="sm:max-w-[600px]">
        <DialogDarkHeader>
          <DialogDarkTitle className="sr-only">{provider.name}</DialogDarkTitle>
          <DialogDescription className="sr-only">
            Add details to your insurance provider.
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <Skeleton className="h-4 w-1/2" />
        </DialogDarkBody>
      </DialogDarkContent>
    );
  }

  return (
    <>
      <DialogDarkContent className="sm:max-w-[600px]">
        <DialogDarkHeader>
          <DialogDarkTitle>Add threads to {provider.name}</DialogDarkTitle>
          <DialogDescription className="sr-only">
            Add insurance provider threads to your tapestry.
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <div className="flex flex-col gap-6 items-center">
            <div className="rounded-lg border border=[#e6e6e6] w-full p-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <Waypoints className="w-4 h-4" />
                  <span className="font-medium">Threads</span>
                </div>

                <Button
                  onClick={() => setAddConnectionModalOpen(true)}
                  className="rounded-full items-center flex gap-2 shadow-none"
                  variant="outline"
                  size="sm"
                >
                  Add more
                  <PlusIcon className="w-3 h-3" />
                </Button>
              </div>
              <div className="flex mt-4 gap-2 flex-wrap">
                {connections.length === 0 && (
                  <span className="text-muted-foreground text-sm">
                    No threads added. Threads are a way to easily organize and
                    thread together your life.
                  </span>
                )}
                {connections.map((connection) => (
                  <ConnectionTag
                    type={connection.type}
                    key={connection.id}
                    name={connection.name}
                    id={connection.id}
                    confidence_score={connection.confidence_score || 100}
                    removeConnection={removeConnection}
                  />
                ))}
              </div>
            </div>
          </div>
          <DialogFooter className="mt-4">
            <Button
              onClick={() => {
                saveConnections(connections);
              }}
              disabled={savingProvider}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogDarkBody>
      </DialogDarkContent>
      <AddConnectionDialog
        item={provider}
        connections={connections}
        setConnections={setConnections}
        open={addConnectionModalOpen}
        onOpenChange={setAddConnectionModalOpen}
        saveConnections={(pendingConnections) => {
          setConnections(pendingConnections);
          setAddConnectionModalOpen(false);
        }}
      />
    </>
  );
}
