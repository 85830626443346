import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { ConnectionSelection } from "./connection-selection";
import { ItemCreationDialog } from "./item-creation-dialog";

export function ConnectionList({
  label,
  loading,
  connectionOptions,
  addPendingConnection,
  pendingConnections,
  searchTerm,
  removeConnection,
  existingItem,
  reload,
}) {
  const [labelOpen, setLabelOpen] = useState(false);

  if (
    connectionOptions[label].filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).length === 0
  )
    return null;
  return (
    <>
      <div className="mb-4">
        <div className="flex justify-between">
          <div className="overflow-hidden p-1 text-foreground px-2 py-1.5 text-xs font-medium text-muted-foreground">
            {determineTitle(label)}
          </div>
          {label === "labels" && (
            <Button
              onClick={() => setLabelOpen(true)}
              className="mr-2 px-2"
              variant="ghost"
            >
              <span className="font-medium text-xs text-muted-foreground">
                Don't see the label you need?
              </span>
            </Button>
          )}
        </div>
        <div className="flex flex-wrap gap-1">
          {loading && <Skeleton className="h-4 w-1/2 ml-2 mt-2" />}
          {!loading &&
            sortArrayWithOtherLast(connectionOptions[label])
              .filter(
                (item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                  (item.id !== existingItem?.id ||
                    item.type !== existingItem?.type)
              )
              .map((item) => {
                return (
                  <ConnectionSelection
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    name={item.name}
                    removeConnection={removeConnection}
                    addPendingConnection={addPendingConnection}
                    selected={
                      !!pendingConnections.find(
                        (connection) =>
                          connection.id === item.id &&
                          connection.type === item.type
                      )
                    }
                  />
                );
              })}
        </div>
      </div>
      <ItemCreationDialog
        open={labelOpen}
        onClose={() => {
          setLabelOpen(false);
        }}
        item="label"
        reload={reload}
      />
    </>
  );
}

function determineTitle(label) {
  if (label === "documents") return "Documents";
  if (label === "people") return "People";
  if (label === "properties") return "Property";
  if (label === "digital_accounts") return "Digital Accounts";
  if (label === "labels") return "Labels";
  if (label === "financial_accounts") return "Financial Accounts";
  if (label === "insurance_providers") return "Insurance Providers";
}

function sortArrayWithOtherLast(arr) {
  if (!arr) return;
  return arr.sort((a, b) => {
    if (a.name === "Other") return 1;
    if (b.name === "Other") return -1;
    return a.name.localeCompare(b.name);
  });
}
