"use client";

import { TrendingUp } from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const chartConfig = {
  count: {
    label: "Documents",
    color: "hsl(var(--chart-1))",
  },
};

export function DocumentActivityChart({ months }) {
  return (
    <div className="h-full w-full">
      <ChartContainer config={chartConfig}>
        <BarChart accessibilityLayer data={months}>
          <CartesianGrid vertical={false} />
          <XAxis
            tick={false}
            dataKey="month"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                labelFormatter={(value) => {
                  return value;
                }}
              />
            }
          />
          <Bar dataKey="count" fill="var(--color-count)" radius={8} />
        </BarChart>
      </ChartContainer>
    </div>
  );
}
