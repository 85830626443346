import React from "react";

const currentYear = new Date().getFullYear();

export const ActivityGrid = ({ months, year = currentYear }) => {
  const getActivityClass = (level) => {
    switch (level) {
      case 0:
        return "bg-gray-100";
      case 1:
        return "bg-green-100";
      case 2:
        return "bg-green-300";
      case 3:
        return "bg-green-500";
      case 4:
        return "bg-green-700";
      default:
        return "bg-green-700";
    }
  };

  return (
    <div
      className="inline-grid grid-cols-6 gap-1 p-3"
      role="grid"
      aria-label={`Activity grid for ${year}`}
    >
      {months.map((month, monthIndex) => (
        <div key={monthIndex} className="text-center" role="gridcell">
          <div
            className={`w-3 h-3 rounded-sm mx-auto ${getActivityClass(
              month.count
            )}`}
            title={`${month.month}: Stories Written ${month.count}`}
          />
          {/* <div className="mt-1 text-xs text-gray-600">
            {monthNames[monthIndex]}
          </div> */}
        </div>
      ))}
    </div>
  );
};
