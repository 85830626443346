import { useState } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";
import axios from "@/axios";
import { toast } from "sonner";

import { Icons } from "@/components/brand/icons";
import { GooseLogo } from "@/components/brand";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
} from "@/components/ui/form";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import { useUser } from "../providers/user-provider";
import { CircleAlert } from "lucide-react";

function createFlock(values, invite) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/flocks`, {
    ...values,
    invite,
  });
}

export function FlockCreator() {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const formSchema = z.object({
    name: z.string().min(1, "Name cannot be blank."),
    focus: z.string().min(1, "The flock focus must be selected."),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().email().or(z.literal("")).optional(),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: `${user.name}'s Tapestry`,
      focus: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  async function onSubmit(values, config = { invite: true }) {
    setLoading(true);
    if (values.focus === "other" && !values.first_name) {
      setError("first_name", {
        type: "manual",
        message: "First name is required when focus is 'other'.",
      });
    }

    if (values.focus === "other" && !values.last_name) {
      setError("last_name", {
        type: "manual",
        message: "Last name is required when focus is 'other'.",
      });
    }

    if (values.focus === "other" && !values.email) {
      setError("email", {
        type: "manual",
        message: "Email is required when focus is 'other'.",
      });
    }

    if (
      values.focus === "other" &&
      (!values.first_name || !values.last_name || !values.email)
    ) {
      setLoading(false);
      return;
    }

    createFlock(values, config.invite)
      .then((res) => {
        const flock = res.data;
        setUser((prev) => ({
          ...prev,
          flocks: [...prev.flocks, flock],
          active_flock_id: flock.id,
        }));

        toast.success("Tapestry created successfully.");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Could not create Tapestry. Please try again.");
      });
  }

  const focusValue = watch("focus");

  return (
    <>
      <div className="h-full flex-col bg-brandGreen py-1 md:py-10 px-4 md:px-10 h-screen">
        <div className="relative flex items-center text-lg font-medium hidden sm:block">
          <GooseLogo />
        </div>

        <div className="relative flex items-center text-lg font-medium block sm:hidden">
          <GooseLogo width={128} height={48} />
        </div>
        <div className="flex items-center justify-center h-[calc(100%-100px)] z-30">
          <Card className="w-[420px]">
            <Form {...rest} control={control}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardHeader>
                  <CardTitle>Create Tapestry</CardTitle>
                  <CardDescription>
                    Input the name of the Tapestry and who it is for.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <div className="flex flex-col space-y-1.5">
                        <FormItem>
                          <FormControl>
                            <>
                              <Label htmlFor="name">Name</Label>
                              <Input id="name" placeholder="Name" {...field} />
                            </>
                          </FormControl>
                        </FormItem>
                      </div>
                    )}
                  />
                  {errors.name && (
                    <FormDescription className="text-red-500">
                      {errors.name.message}
                    </FormDescription>
                  )}

                  <FormField
                    control={control}
                    name="focus"
                    render={({ field }) => (
                      <div className="flex flex-col space-y-1.5 mt-4">
                        <Label htmlFor="focus">Who is the Tapestry for?</Label>
                        <FormItem>
                          <FormControl>
                            <Select onValueChange={field.onChange}>
                              <SelectTrigger id="focus">
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                              <SelectContent position="popper">
                                <SelectItem value="myself">Myself</SelectItem>
                                <SelectItem value="other">
                                  Someone else
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </FormControl>
                        </FormItem>
                      </div>
                    )}
                  />
                  {errors.focus && (
                    <FormDescription className="text-red-500">
                      {errors.focus.message}
                    </FormDescription>
                  )}

                  {focusValue === "other" && (
                    <div className="flex flex-col">
                      <FormField
                        control={control}
                        name="first_name"
                        render={({ field }) => (
                          <div className="flex flex-col space-y-1.5 mt-4">
                            <Label htmlFor="first_name">
                              What is their first name?
                            </Label>
                            <FormItem>
                              <FormControl>
                                <Input {...field} id="first_name" />
                              </FormControl>
                            </FormItem>
                          </div>
                        )}
                      />
                      {errors.first_name && (
                        <FormDescription className="text-red-500">
                          {errors.first_name.message}
                        </FormDescription>
                      )}
                      <FormField
                        control={control}
                        name="last_name"
                        render={({ field }) => (
                          <div className="flex flex-col space-y-1.5 mt-4">
                            <Label htmlFor="last_name">
                              What is their last name?
                            </Label>
                            <FormItem>
                              <FormControl>
                                <Input {...field} id="last_name" />
                              </FormControl>
                            </FormItem>
                          </div>
                        )}
                      />
                      {errors.last_name && (
                        <FormDescription className="text-red-500">
                          {errors.last_name.message}
                        </FormDescription>
                      )}
                      <FormField
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <div className="flex flex-col space-y-1.5 mt-4">
                            <Label htmlFor="email">What is their email?</Label>
                            <FormItem>
                              <FormControl>
                                <Input {...field} id="email" />
                              </FormControl>
                            </FormItem>
                          </div>
                        )}
                      />
                      {errors.email && (
                        <FormDescription className="text-red-500">
                          {errors.email.message}
                        </FormDescription>
                      )}
                    </div>
                  )}
                  {focusValue === "other" && (
                    <Alert className="mt-4 shadow-none bg-yellow-500/50">
                      <CircleAlert className="h-4 w-4" />
                      <AlertTitle>Not ready to invite them?</AlertTitle>
                      <AlertDescription className="text-xs">
                        Click create without inviting. This person will not be
                        notified until you invite them. You can invite them from
                        your vault at a later time.
                      </AlertDescription>
                    </Alert>
                  )}
                </CardContent>
                <CardFooter className="flex justify-between flex-wrap">
                  {focusValue === "other" && (
                    <Button
                      disabled={loading}
                      onClick={handleSubmit((data) =>
                        onSubmit(data, { invite: false })
                      )}
                      type="button"
                      variant="outline"
                    >
                      {loading && (
                        <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Create Without Inviting
                    </Button>
                  )}
                  <Button disabled={loading}>
                    {loading && (
                      <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    {focusValue === "other" ? "Create & Invite" : "Create"}
                  </Button>
                </CardFooter>
              </form>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}
