import dynamic from "next/dynamic";
import { useRef, useState, useEffect } from "react";
import { toast } from "sonner";
import { saveRecording } from "@/app/write/[id]/api";

import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogDescription,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Separator } from "@/components/ui/separator";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Plus, Save, Sparkle } from "lucide-react";

const Editor = dynamic(() => import("@/components/editor"), { ssr: false });
import { VoiceRecorder } from "@/app/write/[id]/components/voice-recorder";
import { Icons } from "@/components/brand/icons";

import { updateStory } from "../api";

export function StoryCanvas({
  open,
  setOpen,
  prompt,
  story,
  users,
  reload,
  setStory,
}) {
  const [loading, setLoading] = useState(false);

  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState(story?.blocks);
  const initialContent = [{}];
  const [authors, setAuthors] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  const content = story?.blocks
    ? story.blocks.length < 1
      ? initialContent
      : story.blocks
    : initialContent;

  useEffect(() => {
    if (story) {
      setAuthors(
        story.people_ids?.map(
          (author) => users.find((user) => user.id === author)?.name
        ) || []
      );
    }
  }, [story]);

  const handleEditorContentChange = (content) => {
    setEditorContent(content);
  };

  if (!story || !prompt) {
    return null;
  }

  function hasEditorContentChanged() {
    return JSON.stringify(editorContent) !== JSON.stringify(story.blocks);
  }

  function hasAuthorsChanged() {
    const originalAuthors = story.people_ids?.map(
      (author) => users.find((user) => user.id === author)?.name
    );

    if (
      (!originalAuthors || originalAuthors.length === 0) &&
      (!authors || authors.length === 0)
    ) {
      return false;
    }

    return JSON.stringify(authors) !== JSON.stringify(originalAuthors);
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        <Sheet
          open={open}
          onOpenChange={() => {
            if (hasAuthorsChanged() || hasEditorContentChanged()) {
              setAlertOpen(true);
            } else {
              setOpen(false);
            }
          }}
        >
          <SheetContent className="h-full" side="bottom">
            <SheetHeader>
              <SheetTitle className="sr-only">Write Story</SheetTitle>
              <SheetDescription className="sr-only">
                Write your story here. You can use markdown to format your text
              </SheetDescription>
            </SheetHeader>
            <div className="flex justify-center gap-4 py-4 bg-white h-full rounded-lg">
              <div className="w-full max-w-[900px] p-4 py-8 border rounded-lg flex flex-col overflow-y-auto">
                <div className="flex-grow">
                  <div className="w-full flex justify-between px-4 md:px-14 items-center gap-8 flex-wrap">
                    <h2 className="text-2xl font-semibold">{prompt.title}</h2>
                    <AudioRecorder story={story} editorRef={editorRef} />
                  </div>
                  <Separator className="max-w-[900px] w-full px-4 md:px-14 my-8" />
                  <Editor
                    endpoint={`${process.env.NEXT_PUBLIC_REACT_APP_API}/stories/${story.slug}/add_image`}
                    initialContent={content}
                    onContentChange={handleEditorContentChange}
                    editable
                    editorRef={editorRef}
                  />
                </div>

                <div className="px-4 md:px-14 flex justify-between w-full items-center flex-wrap">
                  <div className="flex gap-4">
                    <div className="z-0 flex items-center -space-x-1 *:ring *:ring-background">
                      {authors.map((author) => {
                        const user = users.find((user) => user.name === author);
                        return (
                          <Tooltip key={user?.id}>
                            <TooltipTrigger asChild>
                              <Avatar
                                className="z-10 size-8 bg-gray-100"
                                style={{ marginLeft: "-0.25rem" }}
                              >
                                <AvatarImage src={user?.image} />
                                <AvatarFallback>{user?.name[0]}</AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{user?.name}</p>
                            </TooltipContent>
                          </Tooltip>
                        );
                      })}
                    </div>
                    <AuthorsTagging
                      users={users}
                      authors={authors}
                      setAuthors={setAuthors}
                    />
                  </div>
                  <div className="flex gap-4 items-center mt-4">
                    <Button
                      onClick={() =>
                        updateStory({
                          storyId: story.id,
                          blocks: editorContent,
                          authors: users
                            .filter((user) => authors.includes(user.name))
                            .map((user) => user.id),
                          status: "pending",
                        }).then((res) => {
                          setStory(res.data);
                          toast.success("Story saved successfully");
                        })
                      }
                      variant="ghost"
                    >
                      <Save className="mr-2 h-4 w-4" />
                      Save Progress
                    </Button>
                    <Button
                      onClick={() => {
                        setLoading(true);
                        updateStory({
                          storyId: story.id,
                          blocks: editorContent,
                          authors: users
                            .filter((user) => authors.includes(user.name))
                            .map((user) => user.id),
                          status: "complete",
                        }).then(() => {
                          setLoading(false);
                          setOpen(false);
                          reload();
                          toast.success("Story saved successfully");
                        });
                      }}
                      variant="default"
                      disabled={loading}
                    >
                      {loading ? (
                        <Icons.spinner className="mr-2 h-4 w-4" />
                      ) : (
                        <Sparkle className="mr-2 h-4 w-4" />
                      )}
                      I'm Finished
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      </div>
      <AlertDialog open={alertOpen} onOpenChange={() => setOpen(false)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>You have unsaved changes?</AlertDialogTitle>
            <AlertDialogDescription>
              If you exit, this will discard all of your changes, and you will
              not be able to recover them. If you wish to save, click Cancel and
              then click Save Progress before exiting.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              Back to Story
            </Button>
            <AlertDialogAction
              className="bg-red-500 hover:bg-red-800"
              onClick={() => setAlertOpen(false)}
            >
              Discard Changes
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

const AudioRecorder = ({ story, editorRef }) => {
  const addAudioElement = (blob) => {
    saveRecording({ slug: story.slug, recording: blob })
      .then((res) => {
        editorRef.current.insertBlocks(
          [{ type: "audio", props: { url: res.data.url } }],
          editorRef.current.document[editorRef.current.document.length - 1],
          "after"
        );
        toast.success("Recording saved");
      })
      .catch((err) => {
        toast.error("Failed to save recording.");
      });
  };

  return (
    <VoiceRecorder
      classes={{ AudioRecorderClass: "!bg-sky-400" }}
      handleRecordingComplete={addAudioElement}
    />
  );
};

const AuthorsTagging = ({ users, authors, setAuthors }) => {
  const toggleAuthor = (name) => {
    setAuthors((prev) =>
      prev.includes(name)
        ? prev.filter((author) => author !== name)
        : [...prev, name]
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          Tag People
          <Plus className="ml-2 w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Tag People</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {users.map((user) => (
          <DropdownMenuCheckboxItem
            key={user.id}
            checked={authors.includes(user.name)}
            onCheckedChange={() => toggleAuthor(user.name)}
          >
            {user.name}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
