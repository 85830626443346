import axios from "@/axios";

export function getDocuments({ per, search, from, to }) {
  const queryParams = buildQueryParams({ per_page: per, search, from, to });
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents?${queryParams}`
  );
}

export function getDocument(id) {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${id}`);
}

export function uploadDocuments(acceptedFiles) {
  const formData = new FormData();

  acceptedFiles.forEach((file) => {
    formData.append("link[]", file, file.name);
  });

  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents`,
    formData
  );
}

export function deleteDocument(documentId) {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${documentId}`
  );
}

export function downloadDocument(documentId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${documentId}/download`,
    {
      responseType: "blob",
    }
  );
}

export function summarizeDocument(documentId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${documentId}/summarize`
  );
}

export function fetchDocWithRecommendations(id, context) {
  let queryParams = "";
  if (context) {
    queryParams += `context_id=${context.id}&context_type=${context.type}`;
  }
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${id}/recommend?${queryParams}`
  );
}

export function updateDocument({ id, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${id}`,
    data
  );
}

export function createConnections({ documentId, connections }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/documents/${documentId}/add_connections`,
    { connections }
  );
}

export function fetchConnectionOptions() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/vault`);
}

export function createPerson(data) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/people`, data);
}

export function deletePerson(personId) {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}`
  );
}

export function getPerson(personId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}`
  );
}

export function editPerson({ personId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}`,
    data
  );
}

export function updatePersonPhoto(personId, data) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}`,
    data
  );
}

export function updatePropertyPhoto(propertyId, data) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}`,
    data
  );
}

export function createPersonConnections({ personId, connections }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}/add_connections`,
    { connections }
  );
}

export function createPropertyConnections({ propertyId, connections }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}/add_connections`,
    { connections }
  );
}

export function createProperty(data) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties`,
    data
  );
}

export function getProperty(propertyId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}`
  );
}

export function editProperty({ propertyId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}`,
    data
  );
}

export function deleteProperty(propertyId) {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}`
  );
}

export function fetchPropertyWithRecommendations(id) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${id}/recommend`
  );
}

export function createDigitalAccount(data) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts`,
    data
  );
}

export function deleteDigitalAccount(accountId) {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${accountId}`
  );
}

export function getDigitalAccount(accountId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${accountId}`
  );
}

export function createDigitalAccountConnections({ accountId, connections }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${accountId}/add_connections`,
    { connections }
  );
}

export function editDigitalAccount({ accountId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${accountId}`,
    data
  );
}

export function fetchAccountWithRecommendations(id) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${id}/recommend`
  );
}

export function editFinancialAccount({ digitalAccountId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts/${digitalAccountId}/financial_accounts`,
    data
  );
}

export function getVault({ per, expand, search }) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/vault?per_page=${per}&expand=${expand}&search=${search}`
  );
}

export function getPeople({ per, search }) {
  const queryParams = buildQueryParams({ per_page: per, search });
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people?${queryParams}`
  );
}

export function getProperties({ per, search }) {
  const queryParams = buildQueryParams({ per_page: per, search });
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties?${queryParams}`
  );
}

export function getDigitalAccounts({ per, search, type }) {
  const queryParams = buildQueryParams({ per_page: per, search, type });
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts?${queryParams}`
  );
}

function buildQueryParams(params) {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => queryParams.append(key, item));
      } else {
        queryParams.append(key, value);
      }
    }
  });
  return queryParams.toString();
}

export function invitePersonToTapestry({ personId, data }) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}/invite_person_to_tapestry`,
    data
  );
}

export function deletePropertyImage(propertyId) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/properties/${propertyId}/remove_image`
  );
}

export function deletePersonImage(personId) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/people/${personId}/remove_image`
  );
}

export function getLabels() {
  return axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_API}/labels`);
}

export function manageLabels(data) {
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/labels`, data);
}

export function createLinkToken(products) {
  const path = "/plaid/create_link_token";
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}${path}`, {
    products,
  });
}

export function exchangePublicTokenForAccessToken({
  public_token,
  digital_account_id,
  flock_id,
}) {
  const path = "/plaid/set_access_token";
  return axios.post(`${process.env.NEXT_PUBLIC_REACT_APP_API}${path}`, {
    public_token,
    digital_account_id,
    flock_id,
  });
}

export function createAccountViaStatement(formData) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/digital_accounts`,
    formData
  );
}

export function createInsuranceProvider(data) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers`,
    data
  );
}

export function createInsuranceProviderConnections({
  providerId,
  connections,
}) {
  return axios.post(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${providerId}/add_connections`,
    { connections }
  );
}

export function fetchProviderWithRecommendations(id) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${id}/recommend`
  );
}

export function getProviders({ per, search }) {
  const queryParams = buildQueryParams({ per_page: per, search });
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers?${queryParams}`
  );
}

export function deleteInsuranceProvider(providerId) {
  return axios.delete(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${providerId}`
  );
}

export function getInsuranceProvider(providerId) {
  return axios.get(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${providerId}`
  );
}

export function editProvider({ providerId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${providerId}`,
    data
  );
}

export function editInsurancePolicies({ providerId, data }) {
  return axios.put(
    `${process.env.NEXT_PUBLIC_REACT_APP_API}/insurance_providers/${providerId}/policies`,
    data
  );
}
