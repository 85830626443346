import { useState, useEffect } from "react";
import {
  DialogDarkBody,
  DialogDarkContent,
  DialogDarkHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { ArrowRight, Plus, DollarSign, Minus } from "lucide-react";
import { Icons } from "@/components/brand/icons";
import { createInsuranceProvider } from "../api";
import { ConnectInsuranceProvider } from "./connect-insurance-provider";
import { toast } from "sonner";

export function CreateInsuranceProviderDialog({ onClose, reload }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState(null);

  const formSchema = z.object({
    name: z.string().min(1, "Name is required"),
    username: z.string(),
    notes: z.string(),
    url: z.string(),
    policies: z.array(
      z.object({
        name: z.string(),
        coverage_amount: z
          .number()
          .or(z.string().regex(/^\d+(\.\d{1,2})?$/, "Invalid Amount")),
        policy_type: z.string().optional(),
      })
    ),
  });

  const defaultValues = {
    name: "",
    username: "",
    url: "",
    notes: "",
    policies: [{ name: "", coverage_amount: "0.00", policy_type: "" }],
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  async function onSubmit(values) {
    setLoading(true);
    createInsuranceProvider(values)
      .then((res) => {
        setProvider(res.data);
        setStep(2);
        reset();
        toast.success("Insurance provider added successfully");
      })
      .catch((err) => {
        toast.error("Failed to add insurance provider");
        setLoading(false);
      });
  }

  const policies = watch("policies");

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[600px] overflow-y-auto h-full sm:h-auto">
          <DialogDarkHeader>
            <DialogTitle>Add an insurance provider</DialogTitle>
            <DialogDescription className="sr-only">
              Add insurance details to your tapestry.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody className="flex-grow overflow-y-auto">
            <Form {...rest} control={control}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 pb-4">
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="name">Name</Label>
                            <Input placeholder="" id="name" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="url"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="url">Website</Label>
                            <Input id="url" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="username"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <div className="flex justify-start items-center gap-2">
                              <Label htmlFor="username">Username / Email</Label>
                            </div>
                            <Input id="username" placeholder="" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={control}
                    name="policies"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <div className="flex justify-between items-center gap-2">
                              <Label htmlFor="policies">Policies</Label>
                              <Button
                                className="rounded-full"
                                type="button"
                                variant="ghost"
                                onClick={() => {
                                  setValue("policies", [
                                    ...policies,
                                    {
                                      name: "",
                                      coverage_amount: 0,
                                      policy_type: "",
                                    },
                                  ]);
                                }}
                              >
                                <Plus className="w-3 h-3 mr-2" />
                                <span className="text-sm">Add Another</span>
                              </Button>
                            </div>
                            {policies.map((_, index) => (
                              <div
                                className="flex justify-between gap-2"
                                key={index}
                              >
                                <Input
                                  className="flex-1"
                                  placeholder="Policy Name"
                                  id="policies"
                                  onChange={(e) => {
                                    setValue(
                                      "policies",
                                      policies.map((a, i) =>
                                        i === index
                                          ? { ...a, name: e.target.value }
                                          : a
                                      )
                                    );
                                  }}
                                  value={policies[index].name}
                                />

                                <Select
                                  value={policies[index].policy_type}
                                  onValueChange={(value) => {
                                    setValue(
                                      "policies",
                                      policies.map((a, i) =>
                                        i === index
                                          ? {
                                              ...a,
                                              policy_type: value,
                                            }
                                          : a
                                      )
                                    );
                                  }}
                                >
                                  <SelectTrigger className="flex-1">
                                    <SelectValue placeholder="Policy Type" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      <SelectLabel>Policy Types</SelectLabel>
                                      <SelectItem value="health">
                                        Health
                                      </SelectItem>
                                      <SelectItem value="home">
                                        Homeowners
                                      </SelectItem>
                                      <SelectItem value="renters">
                                        Renters
                                      </SelectItem>
                                      <SelectItem value="auto">Auto</SelectItem>
                                      <SelectItem value="life">Life</SelectItem>
                                      <SelectItem value="disability">
                                        Disability
                                      </SelectItem>
                                      <SelectItem value="long-term-care">
                                        Long-term Care
                                      </SelectItem>
                                      <SelectItem value="other">
                                        Other
                                      </SelectItem>
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>

                                <div className="relative w-1/5">
                                  <DollarSign className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
                                  <Input
                                    id="coverage_amount"
                                    type="number"
                                    placeholder="0.00"
                                    step="1"
                                    value={policies[index].coverage_amount}
                                    onChange={(e) => {
                                      setValue(
                                        "policies",
                                        policies.map((a, i) =>
                                          i === index
                                            ? {
                                                ...a,
                                                coverage_amount: e.target.value,
                                              }
                                            : a
                                        )
                                      );
                                    }}
                                    className="pl-9"
                                  />
                                </div>
                                <Button variant="ghost" size="icon">
                                  <Minus
                                    type="button"
                                    className="w-4 h-4"
                                    onClick={() => {
                                      setValue(
                                        "policies",
                                        policies.filter((a, i) => i !== index)
                                      );
                                    }}
                                  />
                                </Button>
                              </div>
                            ))}
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="notes">Notes</Label>
                            <Textarea
                              id="notes"
                              placeholder="Add any additional context to this provider."
                              {...field}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <DialogFooter>
                  <Button type="submit" disabled={loading}>
                    {loading ? (
                      <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                      <ArrowRight className="w-4 h-4 mr-2" />
                    )}
                    Next
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <ConnectInsuranceProvider
          provider={provider}
          onClose={onClose}
          reload={reload}
        />
      )}
    </>
  );
}
